import React from 'react';
import Head from 'next/head';
import { createGlobalStyle } from 'styled-components';


const ScrollBlockStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

import Login from '../components/auth/Login';

const LoginPage = () => (
  <>
    <Head>
      <title>Админ: Войти в PIK.Team</title>
    </Head>

    <ScrollBlockStyle />

    <Login />
  </>
);

export default LoginPage;
